import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { diff } from 'deep-object-diff';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import proceduresApolloClient from '@/lib/appsync/procedures';
import getProcedures from '@/graphql/procedures/queries/getProcedures.graphql';
import getProcedureHistory from '@/graphql/procedures/queries/getProcedureHistory.graphql';
import getProcedureByID from '@/graphql/procedures/queries/getProcedureByID.graphql';
import getProcedureResponseCount from '@/graphql/procedures/queries/getProcedureResponseCount.graphql';
import getProceduresStepsJson from '@/graphql/procedures/queries/getProceduresStepsJson.graphql';
import getWorkflowProcedureResponse from '@/graphql/procedures/queries/getWorkflowProcedureResponse.graphql';
import deleteProcedure from '@/graphql/procedures/mutations/deleteProcedure.graphql';
import postProcedureResponse from '@/graphql/procedures/mutations/postProcedureResponse.graphql';
import getProcedureTallies from '@/graphql/procedures/queries/getProcedureTallies.graphql';
import createProcedure from '@/graphql/procedures/mutations/createProcedure.graphql';
import Procedure from '@/interfaces/procedures/Procedure';
import accountModule from '@/store/modules/accountModule';
import localForage from '@/lib/localForage';
import isOnline from 'is-online';


const mapProcedure = (item, parsedResultsDict = false) => ({
  ...item,
  id: item.ProcedureID,
  name: item.ProcedureName,
  type: item.ProcedureType,
  steps: item.ProcedureJson?.steps?.map((s) => ({
    ...s,
    showActions: false,
    showDetails: false,
  })) || [],
  eventType: item.ProcedureJson?.eventType,
  eventWellName: item.NodeID,
  components: item.ProcedureJson?.components,
  vendors: item.ProcedureJson?.vendors,
  estimateCost: item.ProcedureJson?.estimateCost,
  estimateDuration: item.ProcedureJson?.estimateDuration,
  createdAt: item.DateCreated,
  updatedAt: item.DateModified,
  selectedComponents: item.ProcedureJson?.selectedComponents || [],
  selectedVendors: item.ProcedureJson?.selectedVendors || [],
  workflows: parsedResultsDict ? parsedResultsDict[item.ProcedureID]?.workflows || [] : [],
  tasqsToday: parsedResultsDict ? parsedResultsDict[item.ProcedureID]?.count || 0 : 0,
});

@Module({
  dynamic: true,
  namespaced: true,
  name: 'proceduresModule',
  store,
})
class ProceduresModule extends VuexModule {
  procedures: Procedure[] = [];
  workflowProcedureResponse: any[] = []
  procedure = null
  proceduresStepsV2: any[] = []

  proceduresJsonVersion = null

  @Mutation
  addProcedureResponse(procedureResponse): void {
    this.workflowProcedureResponse.push(procedureResponse)
  }

  @Mutation
  setProcedure(procedure): void {
    this.procedure = procedure;
  }


  activeProcedure: Procedure|null = null;

  @Mutation
  setProcedures(data): void {
    this.procedures = data;
  }

  @Mutation
  setActiveProcedure(data): void {
    this.activeProcedure = data;
  }

  @Mutation
  setProceduresStepsV2(data): void {
    this.proceduresStepsV2 = data;
  }

  @Mutation
  setProceduresJsonVersion(data): void {
    this.proceduresJsonVersion = data;
  }










  @Action
async deleteProcedure({
  ProcedureID,
}) {
  try {
    const {
      data: {
        delete_procedure: {
        },
      },
    } : any = await proceduresApolloClient.mutate({
      mutation: deleteProcedure,
      variables: {
        input: {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  ProcedureID: ProcedureID,

        },
      },
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}













  @Action
async postProcedureResponse({
  CompletionDuration,
  ComponentsArray,
  NodeID,
  ProcedureID,
  ProcedureType,
  ResponseComment,
  ResponseOption,
  StepID,
  Username,
  WorkflowID
}) {
  try {
    const {
      data: {
        post_procedure_response: {
        },
      },
    } : any = await proceduresApolloClient.mutate({
      mutation: postProcedureResponse,
      variables: {
        input: {
			  CompletionDuration: CompletionDuration,
			  ComponentsArray: ComponentsArray,
			  NodeID: NodeID,
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  ProcedureID: ProcedureID,
			  ProcedureType: ProcedureType,
			  ResponseComment: ResponseComment,
			  ResponseOption: ResponseOption,
			  StepID: StepID,
			  Username: Username.toLowerCase(),
			  WorkflowID: WorkflowID

        },
      },
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}






  @Action
  async getWorkflowProcedureResponse({
	workflow_task_id
  }): Promise<void> {
    const {
      data: {
        get_workflow_procedure_response: getWorkflowProcedureResponseResults,
      },
    } : any = await proceduresApolloClient.query({
      query: getWorkflowProcedureResponse,
      variables: {
		  input: {
			Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			WorkflowID: workflow_task_id
		  }
      },
    });
	for (var x = 0; x < getWorkflowProcedureResponseResults.results.length; x++) {
		this.addProcedureResponse(JSON.parse(getWorkflowProcedureResponseResults.results[x]))
	}

  }







  @Action
  async getProceduresStepsJson({
	jsonVersion = ''
  }): Promise<void> {
	if (await isOnline()) {
		var input: any = {
			Operator: getConfigEnv('OPERATOR_LOWERCASED')
		}
		if (jsonVersion) {
			// @ts-ignore
			input.Version = jsonVersion
		}else {
      // input.Version = '1646922636.6159925'
    }
		const {
		  data: {
			get_procedures_steps_json: results,
		  },
		} : any = await proceduresApolloClient.query({
		  query: getProceduresStepsJson,
		  variables: {
			input: input
		  }
		});

		var results_parsed = JSON.parse(results.Result)
		this.setProceduresJsonVersion(results.Version)
    console.log(results_parsed)
		this.setProceduresStepsV2(results_parsed.Groups)
		// getTasqListResponses

		await localForage.setItem('getProceduresStepsJsonVersion', results.Version);
		await localForage.setItem('getProceduresStepsJsonGroups', results_parsed.Groups);
	} else {
		const getProceduresStepsJsonVersionResults = await localForage.getItem('getProceduresStepsJsonVersion');
		const getProceduresStepsJsonGroupsResults = await localForage.getItem('getProceduresStepsJsonGroups');
		if (getProceduresStepsJsonVersionResults) {
			this.setProceduresJsonVersion(getProceduresStepsJsonVersionResults)
		}
		if (getProceduresStepsJsonGroupsResults) {
			this.setProceduresStepsV2(getProceduresStepsJsonGroupsResults)
		  }
	}

  }


  @Action
  async getProcedureResponseCount({
	procedure_id
  }): Promise<void> {
    const {
      data: {
        get_procedure_response_count: getProcedureResponseCountResults,
      },
    } : any = await proceduresApolloClient.query({
      query: getProcedureResponseCount,
      variables: {
		operator: getConfigEnv('OPERATOR_LOWERCASED'),
		procedure_id: procedure_id
      },
    });

  }







  @Action
  async getProcedureByID({
	procedure_id
  }): Promise<void> {
    const {
      data: {
        get_procedure_by_id: getProcedureByIDResult,
      },
    } : any = await proceduresApolloClient.query({
      query: getProcedureByID,
      variables: {
		  input: {
			ProcedureID: procedure_id
		  }
      },
    });

	this.setProcedure(JSON.parse(getProcedureByIDResult.result))
}




  @Action
  async getActiveProcedure(procedure): Promise<void> {
    const id = procedure.id;

    const {
      data: {
        get_procedure_history: {
          results,
        },
      },
    } : any = await proceduresApolloClient.query({
      query: getProcedureHistory,
      variables: {
        input: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          procedure_id: id,
        },
      },
    });

    const {
      data: {
        get_procedure_response_count: {
          results_dict: procedureResults,
        },
      },
    } : any = await proceduresApolloClient.query({
      query: getProcedureResponseCount,
      variables: {
        input: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          procedure_id: id,
        },
      },
    });

    const parsedResponsesDict = JSON.parse(procedureResults);

    const parsedHistory = results.map((i) => JSON.parse(i));
    parsedHistory.sort(
      (a, b) => new Date(b.DateModified).getTime() - new Date(a.DateModified).getTime(),
    );

    const changes = [] as any;

    parsedHistory.forEach((result, idx) => {
      const change = diff(
        parsedHistory[idx + 1] || {}, parsedHistory[idx],
      ) as any;
      if (!parsedHistory[idx + 1]) {
        Object.assign(change, {
          initial: true,
        });
      }
      Object.assign(change, {
        modifiedDate: change.DateModified,
        person: change?.Username || change?.ProcedureJson?.Username || undefined,
      });
      changes.unshift(change);
    });

    parsedHistory.reverse();

    changes.map((i: any, idx) => {
      let changeText = '';
      const currentStepsLength = parsedHistory[idx].ProcedureJson?.steps?.length;
      const previousStepsLength = parsedHistory[idx - 1]?.ProcedureJson?.steps?.length;
      if (!i.person) {
        Object.assign(i, {
          person: changes[idx - 1].person,
        });
      }
      if (Object.values(i.ProcedureJson?.steps?.[0]?.id || {}).length) {
        const stepNumbers = Object.keys(i.ProcedureJson?.steps || {}).map((step) => +step + 1).join(', ');
        const multiple = Object.keys(i.ProcedureJson?.steps || {}).length > 1;
        changeText = `Added new ${multiple ? 'steps' : 'step'}: ${stepNumbers}`;
      }
      if (currentStepsLength === previousStepsLength) {
        const stepNumber = +Object.keys(i.ProcedureJson?.steps || {})[0] + 1;
        changeText = `Step #${stepNumber} was edited`;
        if (isNaN(stepNumber)) { // eslint-disable-line
          changeText = '';
        }
      }
      if (previousStepsLength > currentStepsLength) {
        const stepNumber = +Object.keys(i.ProcedureJson?.steps || {})[0] + 1;
        changeText = `Step #${stepNumber} was deleted`;
      }
      Object.assign(i, {
        changeText,
      });
      return i;
    });

    changes.reverse();

    Object.assign(this.activeProcedure, { changes, parsedResponsesDict });

    this.setActiveProcedure(this.activeProcedure)
  }

  @Action({
    commit: 'setProcedures',
  })
  async getProcedures(): Promise<Procedure[]> {
    try {
      const {
        data:
          {
            get_procedures: {
              results,
            },
          },
      } : any = await proceduresApolloClient.query({
        query: getProcedures,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });

      const {
        data: {
          get_procedures_tallies: {
            return_dict: resultsDict,
          },
        },
      } : any = await proceduresApolloClient.query({
        query: getProcedureTallies,
        variables: {
          input: {
            operator: getConfigEnv('OPERATOR_PASCALED'),
          },
        },
        fetchPolicy: 'network-only',
      });

      const parsedResultsDict = jsonParse(resultsDict);

      const items = results.map((i) => mapProcedure(
        jsonParse(i),
        parsedResultsDict,
      ));

      items.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

      return items;
    } catch (e) {
      console.error(`Error while fetching procedures: ${e}`);
      return [];
    }
  }

  @Action
  async editActiveProcedure(procedure): Promise<void> {
    const specificProcedureProps = {
      eventType: procedure.eventType,
      eventWellName: procedure.eventWellName,
      estimateCost: procedure.estimateCost,
      estimateDuration: procedure.estimateDuration,
    };

    // const vendorIds = procedure.selectedVendors;
    // await Promise.all(rootState.schedule.vendors.map(async (vendor) => {
    //   if (vendor.noBackendDelete) {
    //     procedure.selectedVendors.splice(procedure.selectedVendors.indexOf(vendor.id), 1);
    //     // eslint-disable-next-line max-len
    //     const { data: { post_new_vendor: { vendor_id: vendorId } } } = await scheduleApolloClient.mutate({
    //       mutation: postNewVendor,
    //       variables: {
    //         input: {
    //           Operator: getEnv('OPERATOR'),
    //           VendorContactEmail: vendor.email,
    //           VendorContactFirstName: vendor.firstname,
    //           VendorContactLastName: vendor.lastname,
    //           VendorContactPhoneNumber: vendor.phone,
    //           Vendor: vendor.name,
    //         },
    //       },
    //     });
    //     vendorIds.push(vendorId);
    //   }
    //   return true;
    // }));
    // const componentsIds = procedure.selectedComponents;
    // await Promise.all(rootState.schedule.components.map(async (comp) => {
    //   if (comp.noBackendDelete) {
    //     procedure.selectedComponents.splice(procedure.selectedComponents.indexOf(comp.id), 1);
    //     // eslint-disable-next-line
    //     const { data: { post_new_component: { component_id: compId } } } = await scheduleApolloClient.mutate({
    //       mutation: postNewComponent,
    //       variables: {
    //         input: {
    //           ComponentName: comp.name,
    //           Operator: getEnv('OPERATOR'),
    //         },
    //       },
    //     });
    //     componentsIds.push(compId);
    //   }
    //   return true;
    // }));

    await proceduresApolloClient.mutate({
      mutation: createProcedure,
      variables: {
        input: {
          ProcedureID: procedure.id,
          ProcedureType: procedure.type,
		  NodeID: procedure.eventWellName,
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          ProcedureJson: JSON.stringify({
            // ...procedure.type === 'specific' && {
            //   ...specificProcedureProps,
            //   selectedComponents: componentsIds,
            //   selectedVendors: vendorIds,
            // },
            steps: procedure.steps.map((s) => ({
              id: s.id,
              order: s.order,
              title: s.title,
              text: s.text,
              responses: s.responses,
              exclusions: s.exclusions,
              action: s.action,
            })),
          }),
          ProcedureName: procedure.name,
          Username: accountModule.user.email.toLowerCase(),
        },
      },
    });
    // await this.getProcedures();
  }
}

export default getModule(ProceduresModule);
